* {
  box-sizing: border-box;
}

.center_panel__submit {
  width: 100%;
  border: 4px solid white;
  border-radius: 15px;
  background-color: transparent;
  padding: 0.5rem 0;
  color: white;
  font-family: helvetica;
  font-weight: 700;
  font-size: 1.3rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.3s;
}

.center_panel__submit:hover {
  background-color: white;
  color: black;
}

.bkgd_image--desktop {
  width: 100%;
  height: auto;
}

.bkgd_image--mobile {
  width: auto;
  height: 100%;
}

.login-form__button {
  margin: 0.9rem 2rem;
  background-color: lightblue;
  border: 3px solid lightblue;
  border-radius: 20px;
  padding: 0.3rem 3rem;
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  box-sizing: border-box;
}

.login-form__button:hover {
  border: 3px solid lightblue;
  color: lightblue;
  background-color: transparent;
}

.login-form__button:active {
  color: grey;
  border-color: grey;
}

.email-list__list-item:hover {
  background-color: rgba(255, 235, 205, 0.4);
}

.email-list__cancel-btn:hover {
  background-color: gray !important;
}

.email-list__cancel-btn:active {
  background-color: lightgray !important;
}
